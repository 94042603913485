









































import { defineComponent } from "@vue/composition-api";

import PageHeader from "@/components/PageHeader.vue";
import setPageTitle from "@/compositions/setPageTitle";

export default defineComponent({
  name: "Imprint",

  components: {
    PageHeader,
  },

  setup() {
    setPageTitle("views.imprint.pageTitle");
  },
});
